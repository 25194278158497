export const MAX_IMG_SIZE = 2000000;
export const MAX_FILE_SIZE = 1000000;
export const DEFAULT_AVATAR = "/assets/media/image/avatar.png";
export const DEFAULT_BUSINESS_LOGO = "/assets/media/image/default-profile.png";
export const WEBHOOK_EVENTS = [
                                { label: "Failed Transactions", value: "transaction_failed" },
                                { label: "Successful Transactions", value: "transaction_success" },
                                { label: "Updated Transactions", value: "transaction_updated" },
                                { label: "Completed Payouts", value: "payout_completed" },
                                { label: "Failed Payouts", value: "payout_failed" },
                                { label: "Received Payouts", value: "payout_received" },
                                { label: "Cancelled Payouts", value: "payout_cancelled" },
                                { label: "All Webhooks", value: "all" }
                            ];
